import clsx from 'clsx';
import Enllac from 'components/3cat/Enllac';
import Icona from 'components/3cat/ui/Icona';
import { usePercentatgeVist } from 'hooks/3cat/usePercentatgeVist';
import { useProducte } from 'hooks/3cat/useProducte';
import __isEmpty from 'lodash/isEmpty';
import { useRef, useState } from 'react';
import { FreeMode, Navigation } from 'swiper';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import { Swiper, SwiperSlide } from 'swiper/react';
import Slide from './Slide';
import { addSlideClass, calculaUrlBotoMes, getInfoTitol, getPropsSwiper, getVisibleSlides } from './funcions';
import styles from './slider.module.scss';

const _netejaTitolTira = (titol) => {
    if (!titol) return titol;
    return titol.replace(/\*\[([^\][]*)]\*/g, '').trim();
};

const _testId = (tipus, titol, format) => {
    if (tipus === 'RECOMANEM') return 'tira-recomanats';
    if (tipus === 'MEVA_LLISTA') return 'tira-meva-llista';
    if (tipus === 'ESTAS_VEIENT' && titol == 'Estic veient') return 'tira-estic-veient';
    if (tipus === 'ESTAS_VEIENT' && titol == 'Estic escoltant') return 'tira-estic-escoltant';
    if (__isEmpty(tipus) && __isEmpty(format)) return `tira-${titol.toLowerCase()}`;
    return `tira-${tipus}-${format}`;
};

export default function Slider(props) {
    const {
        iconaTitol,
        iconaTitolAlt,
        has_link,
        ranking,
        items,
        tipus,
        urlDadesByProducte,
        midaImatge,
        nomPrograma,
        slugRelacio,
        breakpoints,
        cercadorLayout,
        format,
        estilModul,
        estilSwiper,
        isFilter,
        handlerFilter,
        showLogoPrograma,
        showProgramTitle,
        linkTitle,
        routeType,
        hideAvantitol
    } = props;

    const routeTitol = {
        slug: nomPrograma,
        type: linkTitle?.linkTipus,
        slugRelacio: linkTitle?.linkSlugRelacio,
        id: linkTitle?.linkIdRelacio
    };

    const titol = _netejaTitolTira(props.titol);
    const { isWebviewActive } = useProducte();
    const { itemsPercentatge } = usePercentatgeVist(items);
    const navigationPrev = useRef(null);
    const navigationNext = useRef(null);
    const swiper = useRef(null);
    const [classPos, setClassPos] = useState('');
    const [swiperInit, setSwiperInit] = useState(false);
    const [swiperMoved, setSwiperMoved] = useState(false);
    const isAudio = midaImatge === 'web_quadrat';
    const isPoster = midaImatge === 'web_postergran' || midaImatge === 'web_posterpetit';
    const isKeyframe = midaImatge === 'web_keyframe';
    const isDirecte = tipus === 'DIRECTES' || tipus === 'DIRECTES_FUTUR';
    const isSeccio = tipus === 'seccions';
    const isMidaLliure = tipus === 'midalliure' || tipus === 'desFoto';
    const isRanking = ranking || tipus === 'RANQUING';
    const icona = isAudio ? 'auriculars_ple' : '';
    const [visibleSlides, setVisibleSlides] = useState(0);
    const sliderId = 'id' + (titol + tipus + format).replace(/^[^a-z]+|[^\w:.-]+/gi, '');
    const clickTitles = !__isEmpty(items) ? items?.map((item) => item.titol).join('|') : '';
    let resizeTimeOut;

    const stylesSwiper = clsx({
        [styles.innerSlider]: true,
        [styles.dreta]: classPos === 'openRight',
        [styles.esquerra]: classPos === 'openLeft',
        [styles[estilSwiper]]: !!estilSwiper
    });

    const stylesModul = clsx({
        [styles.slider]: true,
        [styles.init]: swiperInit,
        [styles.cRanking]: isRanking,
        [styles.cCercador]: cercadorLayout,
        [styles.cSeccions]: isSeccio,
        [styles.cMidaLliure]: isMidaLliure,
        [styles[estilModul]]: !!estilModul,
        [styles.noTitol]: __isEmpty(titol),
        [styles.tiraKeyframe]: format === 'KEYFRAME',
        [styles.webview]: isWebviewActive
    });

    const stylesPoster = clsx({
        [styles.poster]: isPoster,
        [styles.quadrat]: isAudio,
        [styles.cCercadorSwipper]: cercadorLayout
    });

    const _iterateItems = (items) => {
        if (!swiperMoved) {
            return !__isEmpty(items)
                ? Array.from(items)
                      .slice(0, visibleSlides)
                      .map((element, i) => _buildSlide(element, i))
                : null;
        } else {
            return !__isEmpty(items) ? Array.from(items).map((element, i) => _buildSlide(element, i)) : null;
        }
    };

    const _buildSlide = (element, i) => {
        if (isFilter) {
            return (
                <SwiperSlide key={element.id} virtualIndex={i}>
                    <div className={`${styles.wrapper} ${divHover}`}>
                        <div className={styles.imatgeWrapper} onClick={() => handlerFilter(element)}>
                            {_showImageWrapper()}
                        </div>
                    </div>
                </SwiperSlide>
            );
        }
        const marcatgeAudienciesClick = {
            clickName: titol,
            clickType: 'Tira',
            clickPosition: i + 1,
            clickTitles: clickTitles
        };
        return (
            <SwiperSlide
                key={element.id}
                onMouseEnter={(e) => setClassPos(addSlideClass(e, swiper))}
                virtualIndex={i}
                data-testid={`slider-item-${i}`}
            >
                <Slide
                    dataTestId={`slide-${titol.toLowerCase()}-${i}`}
                    element={element}
                    tipus={tipus}
                    midaImatge={midaImatge}
                    nomPrograma={nomPrograma}
                    slugRelacio={slugRelacio}
                    routeType={routeType}
                    format={format}
                    isFilter={isFilter}
                    isSeccio={isSeccio}
                    isMidaLliure={isMidaLliure}
                    isAudio={isAudio}
                    showProgramTitle={showProgramTitle}
                    isWebviewActive={isWebviewActive}
                    estilModul={estilModul}
                    showLogoPrograma={showLogoPrograma}
                    isKeyframe={isKeyframe}
                    icona={icona}
                    isRanking={isRanking}
                    isDirecte={isDirecte}
                    hideAvantitol={hideAvantitol}
                    marcatgeAudienciesClick={marcatgeAudienciesClick}
                />
            </SwiperSlide>
        );
    };

    const routeBotoMes = has_link ? calculaUrlBotoMes(tipus, urlDadesByProducte, items?.[0]) : false;

    const _renderSwiper = () => {
        const propsSwiper = breakpoints ? getPropsSwiper(breakpoints) : getPropsSwiper(midaImatge);

        return (
            <Swiper
                {...propsSwiper}
                ref={swiper}
                navigation={{
                    prevEl: navigationPrev.current,
                    nextEl: navigationNext.current
                }}
                onBeforeInit={(swiper) => {
                    swiper.params.navigation.prevEl = navigationPrev.current;
                    swiper.params.navigation.nextEl = navigationNext.current;
                    setVisibleSlides(getVisibleSlides(propsSwiper));
                }}
                onAfterInit={() => {
                    setSwiperInit(true);
                }}
                modules={[Navigation, FreeMode]}
                className={`${stylesSwiper} ${stylesPoster}`}
                updateOnWindowResize={false}
                onSwiper={(swiper) => {
                    window.addEventListener('resize', function () {
                        clearTimeout(resizeTimeOut);
                        resizeTimeOut = setTimeout(() => {
                            setVisibleSlides(getVisibleSlides(propsSwiper));
                            swiper.update();
                        }, 100);
                    });
                }}
                onSlideChange={() => {
                    setSwiperMoved(true);
                }}
                data-testid={_testId(tipus, titol, format)}
                data-haslink={has_link}
            >
                <button ref={navigationPrev} className={styles.anterior}>
                    <Icona icona='fletxa_simple_esquerra' alt='Anterior' isBlanc={false} mida={30} />
                </button>
                <button ref={navigationNext} className={styles.seguent}>
                    <Icona icona='fletxa_simple_dreta' alt='Següent' isBlanc={false} mida={30} className={styles.iconadreta} />
                </button>
                {_iterateItems(itemsPercentatge || items)}
                {routeBotoMes && (
                    <SwiperSlide className={`${styles.ultimslide}`}>
                        <span className={styles.ultim}>
                            <Enllac route={routeBotoMes} dataTestId='enllac-mes'>
                                + <span className='sr-only'>{titol}</span>
                            </Enllac>
                        </span>
                    </SwiperSlide>
                )}
            </Swiper>
        );
    };
    const route = routeBotoMes ? routeBotoMes : linkTitle ? routeTitol : false;

    return !__isEmpty(items) ? (
        <section className={stylesModul} aria-labelledby={sliderId}>
            {getInfoTitol({ titol, iconaTitol, iconaTitolAlt, route, sliderId })}
            {_renderSwiper()}
        </section>
    ) : (
        <></>
    );
}

Slider.defaultProps = {
    avantitol: '',
    titol: '',
    link: '',
    ranking: '',
    items: {},
    icona: '',
    iconaTitol: '',
    iconaTitolAlt: '',
    midaImatge: 'web_keyframe',
    mostraTitol: true,
    isFilter: false,
    showLogoPrograma: true,
    showProgramTitle: true
};
